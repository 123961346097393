import React from "react";
import { useLocation } from "react-router";
import ProductItem from "theme/modules/ProductView/ProductItem";

const Widget5Products = ({ data, attributes }) => {
  const { products, imageType } = data;
  const productsInStock = products.filter(
    (item) => item?.stock?.isInStock === true
  );
  const location = useLocation();
  const { title } = attributes;

  productsInStock.map((productsInStock) => {
    let selectedImage = imageType.find(
      (item) => item.product_id === parseInt(productsInStock.entity_id)
    )?.type;

    productsInStock.images.forEach((image) => {
      let imageName = image.file;
      let parentLink = productsInStock.kpl_parent_link;
      if((productsInStock.kpl_product_image_prefix !== undefined && productsInStock.kpl_product_image_prefix !== '') &&
          parentLink.startsWith(productsInStock.kpl_product_image_prefix)) {
        parentLink = parentLink.substring(productsInStock.kpl_product_image_prefix.length);
      }
      if(imageName.toUpperCase().search(parentLink.toUpperCase() + "_" + selectedImage.toUpperCase()) > -1) {
        productsInStock.imageUrl = imageName;
      }
    });
    return productsInStock;
  });

  if (productsInStock.length === 0) return null;

  //the widget have 5 products
  //attributes in magento widget xml are named product-one ....
  const productName = ["one", "two", "three", "four", "five"];

  return (
    <div className="block-five-products-container">
      <div className="h5">{title}</div>
      <div className="block-five-products">
        {productsInStock.map((name, index) => (
          <div className={`product-${productName[index]}`}>
            <ProductItem
              key={productsInStock[index].sku}
              product={productsInStock[index]}
              products={productsInStock}
              index={index}
              location={location}
              EnableProductAction
              enableBadges
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Widget5Products;
